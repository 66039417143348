export type Brand = "work" | "sport" | "travel"
export type Domain = "fitforwork.ai" | "fitforsport.ai" | "fitfortravel.ai"
export interface Content {
    [brand: string]: { [content: string]: string }
}
interface Theme {
    brand: Brand,
    domain: Domain,
    color?: { [color: string]: string },
    img?: { [url: string]: string },
    css?: { [css: string]: string}
    url?: { [url: string]: string}
}

export const getTheme = () =>  {
    switch ((window as any).THEME) {
        case "sport":
            return themeSport;
        case "travel":
            return themeTravel;
        default:
            return theme;
    }
}

export const theme: Theme = {
    brand: 'work',
    domain: 'fitforwork.ai',
    color: {
        black: "#000",
        darkGray: "#3d4349",
        lightGray: "#787f86",
        lighterGray: "#f4f8fb",
        slateGray: "#575a62",
        primary: "#73e3bf",
        primaryTransparent: "#73e3bf88",
        lightBlue: "#5db7f8",
        silver: "#e0e1e2",
        coolGray: "#959ea8",
        gunMetal: "#4c575c",
        primaryDarker1: "#4DD4A2"
    },
    img: {
        about1: '/images/about_workers_on_site.jpg',
        about2: '/images/about_workers_with_masks.jpg',
        appStore: '/images/appStore.png',
        customerMorsen: '/images/customer_morson.png',
        customerRec: '/images/customer_rec.png',
        customerVencuro: '/images/customer_vencuro.png',
        dashboardOutline: '/images/dashboard_outline.png',
        featureData: '/images/feature_data.svg',
        featureMessages: '/images/feature_messages.svg',
        featureSpacer1: '/images/feature_spacer_1.svg',
        featureSpacer2: '/images/feature_spacer_2.svg',
        featureVideoCalling: '/images/feature_video_calling.svg',
        heroBg: '/images/hero_background.png',
        logo: '/images/logo.svg',
        menuIcon: '/images/menu_icon.svg',
        mobileWelcome: '/images/mobile_welcome.png',
        partnerAventi: '/images/partner_aventi.png',
        partnerMorson: '/images/partner_morson.png',
        partnerLaing: '/images/partner_laing_orourke.png',
        partnerRec: '/images/partner_rec.png',
        partnerSap: '/images/partner_sap.png',
        partnerShootingStar: '/images/partner_shooting_star.png',
        partnerVencuro: '/images/partner_vencuro.png',
        playIcon: '/images/play_icon.svg',
        playStore: '/images/playStore.png',
        quotes: '/images/quotes.svg',
        missionSafety: '/images/mission_safety.png',
        missionHealth: '/images/mission_health.png',
        missionSupport: '/images/mission_support.png'
    },
    css: {
        heroRepeat: 'repeat-x'
    },
    url: {
        appStore: `https://apps.apple.com/us/app/fit-for-work/id1495784374?ls=1`,
        playStore: `https://play.google.com/store/apps/details?id=com.londontechworks.facecheck`
    }
}

export const themeSport: Theme = {
    ...theme,
    brand: 'sport',
    domain: 'fitforsport.ai',
    color: {
        ...theme.color,
        primary:'#F4C62A',
        primaryTransparent:'#F4C62A88',
        primaryDarker1: '#E5B205'
    },
    img: {
        ...theme.img,
        heroBg: 'background-image: url(/images/hero2_ffs.png), url(/images/hero3_ffs.png), url(/images/hero4_ffs.png), url(/images/hero1_ffs.png)',
        heroBg2x: 'background-image: url(/images/hero2_ffs_@2x.png), url(/images/hero3_ffs_@2x.png), url(/images/hero4_ffs_@2x.png), url(/images/hero1_ffs_@2x.png)',
        logo: '/images/logo_ffsport.svg',
        mobileWelcome: '/images/mobile_welcome_ffs.png',
        featureData: '/images/feature_data_ffs.svg',
        featureMessages: '/images/feature_messages_ffs.svg',
        featureVideoCalling: '/images/feature_video_calling_ffs.svg',
        dashboardOutline: '/images/dashboard_outline_ffs.png',
        quotes: '/images/quotes_ffs.svg',
        missionSafety: '/images/mission_safety_ffs.png',
        missionHealth: '/images/mission_health_ffs.png',
        missionSupport: '/images/mission_support_ffs.png',
        menuIcon: '/images/menu_icon_ffs.svg',
    },
    css: {
        heroSize: 'background-size: 285px auto, 328px auto, 200px auto, 267px auto',
        heroSizeBP1: 'background-size: 120px auto',
        heroRepeat: 'no-repeat',
        heroPos: 'background-position: 27% 100%,75% 90%,97% 12%,3% 12%',
        heroPosBP1: 'background-position: -2000% 58%,90% 78%,50% 100%,8% 78%'
    },
    url: {
        appStore: `https://apps.apple.com/us/app/id1525826155`,
        playStore: `https://play.google.com/store/apps/details?id=com.londontechworks.fitforsport`
    }
}

export const themeTravel: Theme = {
    ...theme,
    brand: 'travel',
    domain: 'fitfortravel.ai',
    color: {
        ...theme.color,
        primary:'#7EC1EB',
        primaryTransparent:'#7EC1EB88',
        primaryDarker1: '#5FACDC'
    },
    img: {
        ...theme.img,
        heroBg: 'background-image: url(/images/hero2_fft.png), url(/images/hero3_fft.png), url(/images/hero4_fft.png), url(/images/hero1_fft.png)',
        heroBg2x: 'background-image: url(/images/hero2_fft_@2x.png), url(/images/hero3_fft_@2x.png), url(/images/hero4_fft_@2x.png), url(/images/hero1_fft_@2x.png)',
        logo: '/images/logo_fftravel.svg',
        mobileWelcome: '/images/mobile_welcome_fft.png',
        featureData: '/images/feature_data_fft.svg',
        featureMessages: '/images/feature_messages_fft.svg',
        featureVideoCalling: '/images/feature_video_calling_fft.svg',
        dashboardOutline: '/images/dashboard_outline_fft.png',
        quotes: '/images/quotes_fft.svg',
        missionSafety: '/images/mission_safety_fft.png',
        missionHealth: '/images/mission_health_fft.png',
        missionSupport: '/images/mission_support_fft.png',
        menuIcon: '/images/menu_icon_fft.svg',
    },
    css: {
        heroSize: 'background-size: 267px auto, 322px auto, 200px auto, 265px auto',
        heroSizeBP1: 'background-size: 120px auto',
        heroRepeat: 'no-repeat',
        heroPos: 'background-position: 27% 100%,75% 90%,97% 12%,3% 12%',
        heroPosBP1: 'background-position: -2000% 58%,90% 78%,50% 100%,8% 78%'
    },
    url: {
        appStore: ``,
        playStore: ``
    }
}