import styled from 'styled-components';
import { fonts } from "../../styles";

interface Props {
    size?: number;
}

export const BulletList = styled.ul<Props>`
    font-family: ${fonts.heroNewMedium};
    color: ${props => props.theme.color.slateGray};
    font-size: ${({ size = 18}) => size}px;
    line-height: ${({ size = 18}) => size * 1.66}px;
    text-align: left;
    max-width: 480px;
    margin-bottom: 20px;
    list-style-position: inside;
    @media only screen and (max-width: 750px) {
        font-size: ${({ size = 16}) => size}px;
        line-height: ${({ size = 16}) => size * 1.66}px;
    }
`;