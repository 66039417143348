import React, { useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Header, Paragraph } from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

export const Download = () => {
    const theme = useContext(ThemeContext);
    const brand: Brand = useContext(ThemeContext).brand;
    
    useEffect(() => {
        function redirect(brand: Brand) {
            if(brand !== 'travel') {
                const os = getOSName();
                if (os === "iOS") {
                    window.location.replace(theme.url.appStore);
                    return;
                }
            
                if (os === "Android") {
                    window.location.replace(theme.url.playStore);
                    return;
                }
            }
        }
        redirect(brand)
    });

    if(brand !== 'travel'){
        return (
            <PlaceholderWrapper>
                <Header>
                    {content[brand].title}
                </Header>
                <Paragraph>
                    If you are not redirected, please use the download links below.
                </Paragraph>
                <div>
                    <a href={theme.url.appStore}>
                        <Logo src={theme.img.appStore} alt="Download App Store" />
                    </a>
                    <a href={theme.url.playStore}>
                        <Logo src={theme.img.playStore} alt="Download Play Store" />
                    </a>
                </div>
            </PlaceholderWrapper>
        )
    } else {
        return(
            <PlaceholderWrapper padding={'150px 0 270px'}>
                <Header>
                    The Fit for Travel App is coming soon...
                </Header>
            </PlaceholderWrapper>
        )
    }
}

export default Download;

const content: Content = {
    work:{
        title: "Downloading the Fit for Work app"
    },
    sport: {
        title: "Downloading the Fit for Sport app",
    }
}
interface Props {
    padding?: string;
}

const PlaceholderWrapper = styled.div<Props>`
    padding: ${({ padding = '100px 0 170px'}) => padding};
    text-align: center;
    p {
        text-align: center;
        margin: auto;
        max-width: none;
    }
`;

const Logo = styled.img`
    margin: 20px;
    width: 250px;
`;


const getOSName = () => {
    let os = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) os = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) os = "Linux";
    if (navigator.appVersion.indexOf("iPhone") !== -1) os = "iOS";
    if (navigator.appVersion.indexOf("Android") !== -1) os = "Android";
    return os;
}