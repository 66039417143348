import React, { FC, memo } from 'react';
import { Link as _Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { fonts } from "../../styles";
import { Paragraph } from "../../components/atoms"


interface Props {
    className?: string;
}

const Footer: FC<Props> = ({ className }) => {
    
    return(
        <Wrapper className={className}>

            <ContactInfo>
                <Email to="mailto:info@fitforwork.ai">info@fitforwork.ai</Email>
                <Phone size={16}>+44 (0) 2071 83 80 58</Phone>
            </ContactInfo>

            <Copy>&copy; 2020 Facecheck Limited. All rights reserved.</Copy>
            
            <Section>
                <Link to="/privacy">Privacy</Link>
                <Link to="/privacy">Terms of use</Link>
            </Section>

        </Wrapper>
    )
}

export default memo(Footer);

const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    border-top: 3px solid ${props => props.theme.color.silver};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
    box-sizing: border-box;

    @media only screen and (max-width: 850px) {
        flex-direction: column;
    }
`;

const Section = styled.div`
    width: 33.3%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media only screen and (max-width: 850px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
`

const linkCss = css`
    font-size: 18px;
    font-family: ${fonts.heroNew};
    text-decoration: none;
    color: ${props => props.theme.color.lightGray};
    @media only screen and (max-width: 850px) {
        font-size: 16px;
        text-align: right;
    }
`;

const Copy = styled.span`
    width: 33%;
    text-align: center;
    ${linkCss}

    @media only screen and (max-width: 850px) {
        width: 100%;
        text-align: center;
        margin: 4px auto;
    }
`;

const Link = styled(_Link)`
    ${linkCss}
    margin-right: 30px;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: ${props => props.theme.color.primaryDarker1};
    }
    @media only screen and (max-width: 850px) {
        text-align: center;
        margin: 4px auto;
        &:last-child {
            margin: 4px auto;
        }
    }
`;

const Email = styled(_Link)`
    ${linkCss}
    margin-bottom: 4px;
    &:hover {
        color: ${props => props.theme.color.primaryDarker1};
    }

    @media only screen and (max-width: 850px) {
        text-align: center;
        margin: 4px auto;
    }
`;


const Phone = styled(Paragraph)`
    
    font-weight: bold;
    color: ${props => props.theme.color.lightGray};
    margin-bottom: 0;

    @media only screen and (max-width: 850px) {
        font-size: 16px;
        margin: 4px auto;
    }
`

const ContactInfo = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-center;

    @media only screen and (max-width: 850px) {
        align-items: flex-end;
        font-size: 16px;
        text-align: right;
        width: 100%;
    }
`