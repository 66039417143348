import React, { memo } from 'react';
import styled from "styled-components";

const Spacer = () => (
    <Wrapper>
        <Line />
    </Wrapper>
)

export default memo(Spacer);

const Wrapper = styled.div`
    padding: 20px;
    text-align: center;
`;

const Line = styled.div`
    background-color: ${props => props.theme.color.silver};
    height: 3px;
    width: 547px;
    max-width: 96%;
    margin: auto;
`;