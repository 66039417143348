import React, { useEffect } from "react";
import styled from "styled-components";
import { Header, Paragraph as _Paragraph } from "../../components/atoms";

export const News = () => {
  
  var ua = window.navigator.userAgent;
  var isIE = /MSIE|Trident/.test(ua);

  useEffect(() => {
    if (!isIE)
     (window as any).startMediumWidget()
  },
  [isIE])
  
  useEffect(() => window.scrollTo(0, 0), [])

  
  
  return  <Wrapper>
            <Header>The latest from our blog</Header>
            {isIE && <Paragraph>Sorry! News is not available on Internet Explorer. Please visit <a href="https://medium.com/@fit-for-work">our blog</a> for the latest.</Paragraph>}
            {!isIE && <MediumWidget id="medium-widget"/>}
          </Wrapper>
}

export default News;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px
`

const MediumWidget = styled.div`

  margin: 50px auto;
  max-width: 730px;

  @media (max-width: 750px) {
    margin: 10px 20px;
  }
`

const Paragraph = styled(_Paragraph)`
  margin: 50px auto;
`