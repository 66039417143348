import React, { memo, useState, useEffect } from 'react';
import styled from "styled-components";
import Hero from "./Hero";
import WorkingWith from "./WorkingWith";
import MobilePreview from "./MobilePreview";
import Features from "./Features";
import Spacer from "./Spacer";
import Dashboard from "./Dashboard";
import Testimonials from "./Testimonials";
import VideoOverlay from "./VideoOverlay";

const Home = () => {

    useEffect(() => window.scrollTo(0, 0), [])

    const [videoVisible, setVideoVisible] = useState({isVisible: false, videoId: ""});

    const openVideo = (videoId: string) => () => setVideoVisible({isVisible: true, videoId: videoId});

    return (
        <Wrapper>
            {videoVisible.isVisible && <VideoOverlay videoId={videoVisible.videoId} onClose={() => setVideoVisible({isVisible: false, videoId: ""})} />}
            <Hero onPlayClick={openVideo("eqicWdPkIQM")} />
            <WorkingWith />
            <MobilePreview onPlayClick={openVideo("lRqXImEdKXU")} />
            <Features />
            <Spacer />
            <Dashboard onPlayClick={openVideo("lRqXImEdKXU")} />
            <Spacer />
            <Testimonials />
        </Wrapper>
    );
}

export default memo(Home);

const Wrapper = styled.div``;
