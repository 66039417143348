import React, { FC, useState, memo } from 'react';
import { BrowserRouter, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import Menu from "./Menu";
import MenuFullScreen from "./MenuFullScreen";
import Footer from "./Footer";
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { CookieNotice } from "../../components/molecules";
import { ThemeProvider } from "styled-components";
import { getTheme } from '../../styles/theme'

interface Props extends RouteComponentProps {}

const Layout: FC<Props> = ({ children, location }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const openMenu = () => setMenuVisible(true);
    const closeMenu = () => setMenuVisible(false);
    const { y } = useWindowScrollPosition({ throttle: 100 });
    const floatingClass = y > 120 ? "visible" : "";
    

    return (
        <ThemeProvider theme={ currentTheme }>
            <Wrapper>
                <BrowserRouter>
                    <Menu onMenuPress={openMenu} />
                    {menuVisible && <MenuFullScreen onClose={closeMenu} />}
                    <FloatingMenu className={floatingClass} onMenuPress={openMenu} />
                    <div>
                        {children}
                    </div>
                    <Footer />
                    <CookieNotice />
                </BrowserRouter>
            </Wrapper>
        </ThemeProvider>
    )
}
export default memo(withRouter(Layout));

const currentTheme = getTheme()

const Wrapper = styled.div`
    height: 100px;
`;

const FloatingMenu = styled(Menu)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background: ${props => props.theme.color.lighterGray};
    border-bottom: 1px solid ${props => props.theme.color.silver};
    margin-top: -80px;
    transition: all 0.3s;
    z-index: 1;
    &.visible {
        margin-top: 0px;
    }
`;
