import React, { FC, memo, useState, useEffect } from 'react';
import styled from "styled-components";

interface Props {
    className?: string;
    onClose: VoidFunction;
    children?: any;
}

const _DismissableOverlay: FC<Props> = ({ className, onClose, children }) => {
    const [visible, setVisible] = useState(false);
    const visibleClassName = `${className} ${visible ? `visible` : ``}`;
    useEffect(() => setVisible(true), []);
    return (
        <Wrapper className={visibleClassName}>
            <Close onClick={onClose}>×</Close>
            {children}
        </Wrapper>
    )
}

export const DismissableOverlay = memo(_DismissableOverlay);

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.color.primary};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    transition: all 0.5s;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.visible {
        opacity: 1;
    }
`;

const Close = styled.div`
    position: fixed;
    top: 10px;
    right: 30px;
    font-size: 50px;
    color: ${props => props.theme.color.darkGray};
    &:hover {
        cursor: pointer;
    }
`;
