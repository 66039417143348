import React, { memo, useContext } from 'react';
import styled, {ThemeContext} from "styled-components";
import { fonts } from "../../styles";

const WorkingWith = () => {
    const theme = useContext(ThemeContext)
    return(
        <Wrapper>
            <Label>
                Proudly working with
            </Label>
            <PartnerImages>
                <PartnerImage src={theme.img.partnerRec} />
                <PartnerImage src={theme.img.partnerVencuro} />
                <PartnerImage src={theme.img.partnerMorson} />
                {/* <PartnerImage src={theme.img.partnerLaing} /> */}
                <PartnerImage src={theme.img.partnerAventi} />
                {/* <PartnerImage src={theme.img.partnerShootingStar} /> */}
                <PartnerImage src={theme.img.partnerSap} />
            </PartnerImages>
        </Wrapper>
    )
}

export default memo(WorkingWith);

const Wrapper = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
`;

const Label = styled.span`
    color: ${props => props.theme.color.lightGray};
    font-size: 14px;
    font-family: ${fonts.heroNewMedium};
`;

const PartnerImages = styled.div`
    margin-top: 20px;
    text-align: center;
`;

const PartnerImage = styled.div<{ src: string }>`
    display: inline-block;
    margin: 10px 25px;
    width: 170px;
    height: 46px;
    background-image: url(${({ src }) => src});
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (max-width: 750px) {
        width: 86px;
        height: 24px;
    }
`;