import React, { useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Header, Paragraph } from "../../components/atoms";

const ANDROID_LINK = `https://play.google.com/store/apps/details?id=com.londontechworks.fitforsport`;
const IOS_LINK = `https://apps.apple.com/us/app/id1525826155`;

export const DownloadSport = () => {
    useEffect(redirect, []);
    const theme = useContext(ThemeContext);
    return (
        <PlaceholderWrapper>
            <Header>
                Download the Fit for Sport app now
            </Header>
            <Paragraph>
                Redirecting you... didn't re-direct? use one of the direct links below
            </Paragraph>
            <div>
                <a href={IOS_LINK}>
                    <Logo src={theme.img.appStore} alt="Download App Store" />
                </a>
                <a href={ANDROID_LINK}>
                    <Logo src={theme.img.playStore} alt="Download Play Store" />
                </a>
            </div>
        </PlaceholderWrapper>
    )
}

export default DownloadSport;

const PlaceholderWrapper = styled.div`
    padding-top: 200px;
    padding-bottom: 200px;
    text-align: center;
    p {
        text-align: center;
        margin: auto;
        max-width: none;
    }
`;

const Logo = styled.img`
    margin: 20px;
    width: 250px;
`;

const redirect = () => {
    const os = getOSName();
    if (os === "iOS") {
        window.location.replace(IOS_LINK);
        return;
    }

    if (os === "Android") {
        window.location.replace(ANDROID_LINK);
        return;
    }
}

const getOSName = () => {
    let os = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) os = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) os = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) os = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) os = "Linux";
    if (navigator.appVersion.indexOf("iPhone") !== -1) os = "iOS";
    if (navigator.appVersion.indexOf("Android") !== -1) os = "Android";
    return os;
}