import React, { useState } from "react";
import styled from 'styled-components';
import { Paragraph as _Paragraph } from '../atoms/Paragraph';

export const CookieNotice = () => {
    const [visible, setVisible] = useState(!isCookieAccepted());
    const dismiss = () => {
        setVisible(false);
        setCookieAccepted();
    }
    if (!visible) {
        return null;
    }
    return (
        <Wrapper>
            <Close onClick={dismiss}>×</Close>
            <Paragraph>
                We use cookies. By continuing to use this site you consent to our use of cookies as described in our cookie policy
            </Paragraph>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    max-width: 366px;
    box-shadow: 1px 5px 50px -29px rgba(0, 0, 0, 0.5);
    background-color: white;
    padding: 24px 18px;
    margin: 20px;
    border-radius: 8px;
`;

const Close = styled.div`
    position: absolute;
    top: 6px;
    right: 10px;
    font-weight: bold;
    font-size: 24px;
    color: ${props => props.theme.color.slateGray};
    &:hover {
        cursor: pointer;
    }
`;


const Paragraph = styled(_Paragraph)`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
`;

const setCookieAccepted = () => window.localStorage.setItem("cookiesAccepted", "1");

const isCookieAccepted = () => !!window.localStorage.getItem("cookiesAccepted");