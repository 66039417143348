import { Environment, Config } from "./types";
import local from "./config.local";
import dev from "./config.dev";
import staging from "./config.staging";
import prod from "./config.prod";

const buildConfig = (): Config => {
    const env: Environment = process.env.REACT_APP_ENV as Environment || "LOCAL" as Environment;
    switch (env) {
        case "LOCAL":
            return local;
        case "DEV":
            return dev;
        case "STAGING":
            return staging;
        case "PROD":
            return prod;
        default:
            return local;
    }
}

export default buildConfig();
