import React, {useEffect} from "react";
import styled from "styled-components";
import { Header, Paragraph } from "../../components/atoms";

export const Placeholder = () => {
    
  useEffect(() => window.scrollTo(0, 0), [])

    return <PlaceholderWrapper>
        <Header>
            contact
      </Header>
        <Paragraph>
            This page is coming soon... check back later
      </Paragraph>
    </PlaceholderWrapper>
}

export default Placeholder;

const PlaceholderWrapper = styled.div`
    padding-top: 200px;
    padding-bottom: 200px;
    text-align: center;
    p {
      text-align: center;
      margin: auto;
    }
`;