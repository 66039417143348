import React, { FC, memo } from 'react';
import styled from "styled-components";
import { DismissableOverlay } from "../../components/organisms";
import YouTube from 'react-youtube';

interface Props {
    onClose: VoidFunction;
    videoId: string;
}

const VideoOverlay: FC<Props> = ({ onClose, videoId }) => {
    const width = (window.innerWidth || 390) * 0.8;
    const height = width / 1.6;
    return (
        <Wrapper onClose={onClose}>
            <YouTube
                videoId={videoId}
                opts={{
                    height: height.toString(),
                    width: width.toString(),
                    playerVars: {
                        autoplay: 1,
                    },
                }}
            />
        </Wrapper>
    )
}

export default memo(VideoOverlay);

const Wrapper = styled(DismissableOverlay)`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;
