import React, { FC } from "react"
import styled from "styled-components";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";

const Team = () => {

    return <Wrapper>
        <Header size={30}>Meet the Founders</Header>
        <Scroller>
            {teamDetails.map((td, i) => <Card key={i} {...td}></Card>)}
        </Scroller>
    </Wrapper>
}

export default Team

const teamDetails = [
    {
        name: "Ben Chester",
        jobTitle: "Technical Director",
        description: "Ben is the technical architect of our products and leads our integration work with third party systems.",
        photo: "/images/team_ben.jpg",
        linkedIn: "https://www.linkedin.com/in/ben-chester-15bb6544",
    },
    {
        name: "Josh Heap",
        jobTitle: "Product Director",
        description: "Josh heads up our product strategy as well as our branding and marketing activities.",
        photo: "/images/team_josh.jpg",
        linkedIn: "https://www.linkedin.com/in/joshheap/",
    },
    {
        name: "Stephen Reilly",
        jobTitle: "Customer Director",
        description: "Stephen leads our customer strategy and our customisation work for our client base.",
        photo: "/images/team_stephen.jpg",
        linkedIn: "https://www.linkedin.com/in/stephenreillyuk/",
    },
]

const Wrapper = styled.div`
    background-color: ${props => props.theme.color.lighterGray};
    width: 100vw;
    padding-top: 40px;
`

const Scroller = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;

    &:last-child:after {
        flex: 0 0 1px;
        content: "";
        margin-right: auto;
    }

    &:last-child:before {
        flex: 0 0 1px;
        content: "";
        margin-left: auto;
    }
`

const Header = styled(_Header)`
    margin: 20px auto;
`

type TeamDetailProps = typeof teamDetails[0]

const Card: FC<TeamDetailProps> = ({name, jobTitle, description, photo, linkedIn}) => {

    return <CardWrapper>
        <Photo src={photo} />
        <LinkedIn link={linkedIn}/>
        <Name size={14}>{name}</Name>
        <JobTitle size={14}>{jobTitle}</JobTitle>
        <Description size={14}>{description}</Description>
    </CardWrapper>
}

const CardWrapper = styled.div`
    padding: 20px;
    margin: 40px 40px 120px 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    flex: 0 0 250px;
    background-color: white;
    position: relative;
`

const Photo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 0px 10px 10px 10px;
    object-fit: cover;
    display: inline-block;
`

type LinkedInProps = { link: string }

const LinkedIn: FC<LinkedInProps> = ({link}) => {

    return <LinkedInWrapper>
        <a href={link}>
            <LinkedInLogo src="/images/linkedin_logo.png"/>
        </a>
    </LinkedInWrapper>
}

const LinkedInWrapper = styled.div`
    
    position: absolute;
    right: 20px;
    top: 20px;
`

const LinkedInLogo = styled.img`
    object-fit: contain;
    width: 30px;
    height: 30px;
`

const Name = styled(_Paragraph)`
    font-weight: 500;
    margin: 0px;
`

const JobTitle = styled(_Paragraph)`
    font-weight: bold;
    color: ${props => props.theme.color.primaryDarker1};
`

const Description = styled(_Paragraph)`
    margin: 0px 10px 20px 20px;
    padding: 0px;
    line-height: 1.5em;
    vertical-align: text-top;
    display: inline-block;
    list-style-position: outside;
`

// const DescriptionItem = styled.li`
//     margin-bottom: 1em;
// `