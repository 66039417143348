import React, { memo, useContext } from 'react';
import styled, { ThemeContext} from "styled-components";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

const Features = () => {
    const theme = useContext(ThemeContext);
    const brand: Brand = useContext(ThemeContext).brand;

    return (
        <Section>
            <Header>How it works</Header>
            <Wrapper>
                <Feature>
                    <ImageWrapper>
                        <Image src={theme.img.featureVideoCalling} width={164} height={147} alt="Connect everyone, everywhere" />
                    </ImageWrapper>
                    <Header size={28}>
                        Connect everyone,<br/>everywhere
                    </Header>
                    <Paragraph size={16}>
                        {content[brand].para1}
                    </Paragraph>
                </Feature>

                <Spacer top={120}>
                    <img src={theme.img.featureSpacer1} width={157} alt="spacer" />
                </Spacer>

                <Feature>
                    <ImageWrapper>
                        <Image src={theme.img.featureMessages} width={220} height={215} alt="Engage with everyone daily" />
                    </ImageWrapper>
                    <Header size={28}>
                        Engage with everyone daily
                    </Header>
                    <Paragraph size={16}>
                        {content[brand].para2}
                    </Paragraph>
                </Feature>

                <Spacer top={30} move={-320}>
                    <img src={theme.img.featureSpacer2} width={157} alt="spacer" />
                </Spacer>

                <Feature>
                    <ImageWrapper>
                        <Image src={theme.img.featureData} width={166} height={152} alt="Protect your organisation's wellbeing" />
                    </ImageWrapper>
                    <Header size={28}>
                        Protect your organisation's wellbeing
                    </Header>
                    <Paragraph size={16}>
                        {content[brand].para3}
                    </Paragraph>
                </Feature>


            </Wrapper>
        </Section>
    )
}

export default memo(Features);

const content:Content = {
    work: {
        para1: "Get the right message to the right worker in the right place at the right time, using facial recognition and geo-location.",
        para2: "Start a daily conversation with each worker about the health, safety and wellbeing of them and their co-workers.",
        para3: "Make informed decisions as an organisation to improve the lives of your workers, customers and visitors."
    },
    sport: {
        para1: "Identify and protect the people accessing your sports environment, using facial recognition and geo-location.",
        para2: "Start a conversation with each member about the health, safety and wellbeing of them and their fellow members.",
        para3: "Make informed decisions as an organisation to improve the lives of your members, workers and visitors."
    },
    travel: {
        para1: "Identify and protect the people accessing your facilities, using facial recognition and geo-location.",
        para2: "Start a conversation with each passenger about the health, safety and wellbeing of them and their fellow passengers.",
        para3: "Make informed decisions as an organisation to improve the lives of your passengers, workers and visitors."
    }
}

const Section = styled.section`
    display:block;
    text-align: center;
    padding-top: 160px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    padding: 0 40px 160px;
    @media only screen and (max-width: 1050px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Spacer = styled.div<{ top: number; move?: number; }>`
    width: 60px;
    overflow: visible;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: ${({ top }) => top}px;
    @media only screen and (max-width: 1050px) {
        transform: rotate(90deg) scaleY(-1);
        padding: 0;
        padding-top: 120px;
        margin-left: ${({ move = 0 }) => move}px;
    }
`;

const Feature = styled.div`
    text-align: center;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
`;

const ImageWrapper = styled.div`
    width: 220px;
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
`;

const Image = styled.img`
    margin: auto;
`;

const Header = styled(_Header)`
    margin-bottom: 20px;
`;

const Paragraph = styled(_Paragraph)`
    line-height: 1.88;
`;
