import React, { useContext } from "react";
import styled, {ThemeContext} from "styled-components";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";

const Mission = () => {

    const theme = useContext(ThemeContext);

    return <Wrapper>
        <Header size={30}>Our Mission</Header>
        <SubHeader>Digital PPE in the hands of every worker</SubHeader>
            <CardFlex>
                <Card>
                    <Image src={theme.img.missionSafety}/>
                    <Header size={30}>Visibility</Header>
                    <Paragraph>We use the latest mobile, web and cloud technologies to give organisations the visibility they need to make better decisions.</Paragraph>
                </Card>
                <Card>
                    <Image src={theme.img.missionHealth}/>
                    <Header size={30}>Safety</Header>
                    <Paragraph>We put the wellbeing of workers at the heart of everything we do and our products are designed to enhance their working lives.</Paragraph>
                </Card>
                <Card>
                    <Image src={theme.img.missionSupport}/>
                    <Header size={30}>Community</Header>
                    <Paragraph>We work together with our customers to enable them to solve their toughest challenges with happier, safer workers.</Paragraph>
                </Card>
            </CardFlex>
    </Wrapper>
}

export default Mission

const Wrapper = styled.div`
`

const Header = styled(_Header)`
    margin: 40px auto 0px auto
`

const SubHeader = styled(_Paragraph)`
    text-align: center;
    margin: 20px auto;
    max-width: 2000px;

    @media only screen and (max-width: 750px) {
        margin: 20px;
    }
`

const CardFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`

const Card = styled.div`
    margin: 80px 60px;
    display: inline-block;
    max-width: 300px;
    min-width: 300px;

    @media only screen and (max-width: 750px) {
        margin: 20px auto;
        max-width: 600px;
    }
`

const Image = styled.img`
    object-fit: contain;
    height: 200px;
    width: 200px;
`

const Paragraph = styled(_Paragraph)`
    margin: 40px auto;

    @media only screen and (max-width: 750px) {
        margin: 20px;
    }
`