import React, { memo, FC } from 'react';
import styled from "styled-components";
import { Header as _Header, Paragraph } from "../../components/atoms";
import "pure-react-carousel/dist/react-carousel.es.css";
import { CarouselProvider, Slider, Slide as CarouselSlide, DotGroup as _DotGroup } from 'pure-react-carousel';
import { fonts } from "../../styles";

const Testimonials = () => (
	<Wrapper>
		<Header>
			What our customers say
        </Header>
		<CarouselWrapper>
			<CarouselProvider
				totalSlides={slides.length}
				naturalSlideWidth={window.innerWidth || 516}
				naturalSlideHeight={580}
				interval={5000}
				isPlaying={true}
			>
				<Slider>
					{slides.map((slide, i) => <CarouselSlide key={i} index={i}><Slide {...slide} /></CarouselSlide>)}
				</Slider>
				<DotGroup />
			</CarouselProvider>
		</CarouselWrapper>
	</Wrapper>
)

export default memo(Testimonials);

const Wrapper = styled.div`
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
`;

const Header = styled(_Header)`
    letter-spacing: -0.65px;
    margin-bottom: 30px;
`;

const CarouselWrapper = styled.div`
    width: 100%;
    margin: auto;
`;

const DotGroup = styled(_DotGroup)`
    button {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 6px;
        border-radius: 6px;
        border: none;
        outline: none;
        background-color: ${props => props.theme.color.gunMetal};
        opacity: 0.4;
        &[disabled] {
            opacity: 1;
        }
    }
`;

const slides = [
	{
		quote: "Using this product allows us to retain our position as an agile business which puts people at the centre.",
		thumbnail: "/images/customer_morson.png",
		name: "Ged Mason OBE",
		company: "CEO, Morson Group",
		logo: "/images/partner_morson.png",
	},
	{
		quote: "With this technology, employees can return to work feeling safe and confident that their employers are looking out for them.",
		thumbnail: "/images/customer_rec.png",
		name: "Neil Carberry",
		company: "CEO, the REC",
		logo: "/images/partner_rec.png",
    },
    {
        quote: "\nOur customers love the simplicity and the weekly insights reports.\n\n",
        thumbnail: "/images/customer_vencuro.jpg",
        name: "Charlotte Lewis",
        company: "Head of Technology, Vencuro",
        logo: "/images/partner_vencuro.png"
    }
];


/**
 * TODO: break below out into a separate component
 */

type SlideProps = (typeof slides)[0];

const Slide: FC<SlideProps> = ({ quote, thumbnail, name, company, logo }) => (
	<SlideWrapper>
		<Quote size={24}>
			{quote}
		</Quote>
		<Person>
			<Thumbnail src={thumbnail} />
			<Name>{name}</Name>
			<Company>{company}</Company>
			<Logo src={logo} />
		</Person>
	</SlideWrapper>
)

const SlideWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const Quote = styled(Paragraph)`
    font-family: ${fonts.heroNewMedium};
    background-image: url(${props => props.theme.img.quotes});
    background-repeat: no-repeat;
    background-position: center center;
    padding: 24px;
    margin-bottom: 50px;
    white-space: pre-wrap;
    @media only screen and (max-width: 750px) {
      margin-bottom: 20px;
    }
`;

const Person = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const Thumbnail = styled.img`
    width: 124px;
    height: 125px;
`;

const Name = styled(Paragraph)`
    margin: 0;
    color: ${props => props.theme.color.slateGray};
    font-weight: 500;
`;

const Company = styled(Name)`
    color: ${props => props.theme.color.slateGray};
`;

const Logo = styled.img`
    width: 125px;
    margin: 40px 0px;
`;