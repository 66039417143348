import React, { FC, memo, useContext } from 'react';
import styled, {ThemeContext} from "styled-components";
import { fonts } from "../../styles";
import { Header as _Header, Paragraph as _Paragraph } from "../../components/atoms";
import { Content, Brand } from "../../styles/theme"

interface Props {
    onPlayClick: VoidFunction;
}

const MobilePreview: FC<Props> = ({ onPlayClick }) => {
    const theme = useContext(ThemeContext);
    const brand: Brand = theme.brand;
    
    return (
        <Wrapper>
            <div>
                <Image src={theme.img.mobileWelcome} alt="Mobile" />
            </div>
            <Spacer />
            <div>
                <Header size={48}>
                    Digital PPE at your fingertips
                </Header>
                <Paragraph>
                    {content[brand].para}
                </Paragraph>
                <Link onClick={onPlayClick}>
                    See how it works
                </Link>
            </div>
        </Wrapper>
    )
}

export default memo(MobilePreview);

const content: Content = {
    work: {
        para: 'Protecting the health, safety and wellbeing of workers has never been more important. Every organisation has a duty of care to ensure their workers are safe and free from unnecessary risks. The Fit for Work app puts digital PPE in the hands of workers, visitors and customers - keeping the workplace safe.',
    },
    sport: {
        para: "Protecting the health, safety and wellbeing of your sport's community has never been more important. Every organisation has a duty of care to ensure their environment is safe and free from unnecessary risks. The Fit for Sport app puts digital PPE in the hands of members, workers and visitors - keeping your sport's environment safe.",
    },
    travel: {
        para: "Protecting the health, safety and wellbeing of your passengers has never been more important. Every organisation has a duty of care to ensure their environment is safe and free from unnecessary risks. The Fit for Travel app puts digital PPE in the hands of passengers, workers and visitors - keeping your facilities safe.",
    }
}

const Wrapper = styled.div`
    background-color: ${props => props.theme.color.lighterGray}};
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 90px 40px;
    @media only screen and (max-width: 750px) {
        padding-left: 40px;
        padding-right: 40px;
        flex-direction: column;
    }
`;

const Image = styled.img`
    width: 494px;
    @media only screen and (max-width: 1000px) {
        width: 380px;
    }
    @media only screen and (max-width: 750px) {
        width: 310px;
    }
`;

const Spacer = styled.div`
    width: 80px;
`;

const Header = styled(_Header)`
    letter-spacing: -0.65px;
    line-height: 1;
    max-width: 360px;
    margin: inherit;
    margin-bottom: 20px;
    @media only screen and (max-width: 750px) {
        font-size: 32px;
        line-height: 40px;
        max-width: 300px;
        margin-top: 30px;
    }
`;

const Paragraph = styled(_Paragraph)`
    max-width: 480px;
    margin-bottom: 20px;
`;

const Link = styled.span`
    color: ${props => props.theme.color.lightBlue};
    text-decoration: none;
    font-family: ${fonts.heroNew};
    font-size: 18px;
    font-weight: 500;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.primaryDarker1};
    }
`;