import styled from "styled-components";
import { fonts } from "../../styles";

export const Button = styled.a`
    display: inline-block;
    text-decoration: none;
    font-size: 24px;
    font-family: ${fonts.heroNew};
    font-weight: bold;
    text-decoration: none;
    color: ${props => props.theme.color.darkGray};
    padding: 22px 61px;
    background-color: ${props => props.theme.color.primary};
    @media only screen and (max-width: 750px) {
        font-size: 18px;
        padding: 19px 37px;
    }
`;
